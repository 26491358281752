<template>
	<div class="box_content_wrap">
		<div class="tit_wrap">
			<h2>자주묻는질문</h2>
		</div>
		<div class="tab_wrap">
			<ul>
				<li
					:class="{ active: index == current_btn }"
					v-for="(item, index) in FAQ_list"
					:key="index"
					id="text_list"
				>
					<a style="cursor: pointer" @click="btn_active(index, $event)">{{ item }}</a>
				</li>
			</ul>
		</div>
		<div class="line"></div>
		<div class="notice_wrap">
			<ul v-for="(item, index) in FAQ_content_list" :key="index" class="notice">
				<li>
					<div @click="openAnswer(index)" class="flex_box">
						<div class="tit_date">
							<div class="tit active"><span>Q.</span>{{ item.b_title }}</div>
						</div>
						<span><i id="FAQ_down_icon" class="bx bxs-chevron-down"></i></span>
					</div>
					<div id="FAQ_list" class="notice_box" style="display: none">
						<ul>
							<li>A.</li>
							<li v-html="item.b_content"></li>
						</ul>
					</div>
				</li>
			</ul>
			<div class="pagenation_wrap">
				<ul class="pagenation">
					<Pagination @changePage="page_active" :page="page" :totalPage="totalPage"></Pagination>
				</ul>
			</div>
		</div>
	</div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import ajax from '@/api/api-manager';
import Pagination from '@/shared-components/Pagination.vue';

const FAQ_list = ref(['로그인 · 회원', '결제 · 환불', '서비스이용', '콘텐츠', '문의 · 제안', '기타']);
const current_btn = ref(0);
const current_btn_valid = ref(1);
const FAQ_content_list = ref(null);
const page = ref(null);
const totalPage = ref(null);

onMounted(() => {
	FAQ_paging();
});
const btn_active = (idx, event) => {
	// current_btn.value = event.path[1].__vnode.key;
	current_btn.value = idx;
	current_btn_valid.value = 1;
	Array.from(document.querySelectorAll('#FAQ_down_icon')).map((p) => {
		p.style.transform = 'rotate(360deg)';
	});

	Array.from(document.querySelectorAll('#FAQ_list')).map((p) => {
		p.style.display = '';
	});
	// const test = () => {
	// 	if (event.path[1].__vnode.key === 0) {
	// 		return 'I1';
	// 	} else if (event.path[1].__vnode.key === 1) {
	// 		return 'I2';
	// 	} else if (event.path[1].__vnode.key === 2) {
	// 		return 'I3';
	// 	} else if (event.path[1].__vnode.key === 3) {
	// 		return 'I4';
	// 	} else if (event.path[1].__vnode.key === 4) {
	// 		return 'I5';
	// 	} else if (event.path[1].__vnode.key === 5) {
	// 		return 'I6';
	// 	}
	// };
	const test = () => {
		switch (current_btn.value) {
			case 0:
				return 'I1';

			case 1:
				return 'I2';

			case 2:
				return 'I3';

			case 3:
				return 'I4';

			case 4:
				return 'I5';

			case 5:
				return 'I6';
		}
	};
	const form = new FormData();
	form.append('bType', test());
	form.append('id', current_btn_valid.value);
	ajax('servicecenter/FAQ_list', form).then((res) => {
		FAQ_content_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};
const page_active = (event) => {
	Array.from(document.querySelectorAll('#FAQ_down_icon')).map((p) => {
		p.style.transform = 'rotate(360deg)';
	});

	Array.from(document.querySelectorAll('#FAQ_list')).map((p) => {
		p.style.display = '';
	});
	current_btn_valid.value = event;
	FAQ_paging();
};

const FAQ_paging = () => {
	// const test = () => {
	// 	if (current_btn.value === 0) {
	// 		return 'I1';
	// 	} else if (current_btn.value === 1) {
	// 		return 'I2';
	// 	} else if (current_btn.value === 2) {
	// 		return 'I3';
	// 	} else if (current_btn.value === 3) {
	// 		return 'I4';
	// 	} else if (current_btn.value === 4) {
	// 		return 'I5';
	// 	} else if (current_btn.value === 5) {
	// 		return 'I6';
	// 	}
	// };
	const test = () => {
		switch (current_btn.value) {
			case 0:
				return 'I1';

			case 1:
				return 'I2';

			case 2:
				return 'I3';

			case 3:
				return 'I4';

			case 4:
				return 'I5';

			case 5:
				return 'I6';
		}
	};
	const form = new FormData();
	form.append('bType', test());
	form.append('id', current_btn_valid.value);
	ajax('servicecenter/FAQ_list', form).then((res) => {
		FAQ_content_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};

const openAnswer = (index) => {
	if (document.querySelectorAll('#FAQ_list')[index].style.display === 'none') {
		document.querySelectorAll('#FAQ_list')[index].style.display = 'block';
		document.querySelectorAll('#FAQ_down_icon')[index].style.transform = 'rotate(180deg)';
		document.querySelectorAll('#FAQ_down_icon')[index].style.transition = 'all ease 0.5s';
		// document.querySelectorAll('.bx bxs-chevron-down')[index].style.transform = 'translateY(10px)';
	} else if (document.querySelectorAll('#FAQ_list')[index].style.display === 'block') {
		document.querySelectorAll('#FAQ_down_icon')[index].style.transform = 'rotate(360deg)';
		document.querySelectorAll('#FAQ_list')[index].style.display = 'none';
	}
};
</script>

<style scoped>
#FAQ_down_icon {
	transition: all 0.5s;
}
</style>
