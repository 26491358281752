<template>
	<div class="box_content_wrap">
		<div class="tit_wrap_line">
			<h2>공지사항</h2>
			<!--<button><img alt="" src="images/icon_go_arrow_line.png"></button>-->
		</div>
		<div class="notice_wrap">
			<ul class="notice">
				<li v-for="(item, index) in board_list" :key="index">
					<div :key="index" @click="openAnswer(index)" class="flex_box">
						<div class="tit_date">
							<div class="tit active"><span>[공지]</span>{{ item.b_title }}</div>
							<div class="date">{{ moment(item.reg_date).format('YYYY. MM. DD') }}</div>
						</div>
						<span><i id="down_icon" class="bx bxs-chevron-down"></i></span>
					</div>
					<div :key="index" id="notice_list" class="notice_box" style="display: none">
						<div class="notice_tit">{{ item.b_title }}</div>
						<p v-html="item.b_content"></p>
					</div>
				</li>
			</ul>
			<div class="pagenation_wrap">
				<ul class="pagenation">
					<Pagination @changePage="page_active" :page="page" :totalPage="totalPage"></Pagination>
				</ul>
			</div>
		</div>
	</div>
</template>
<script setup>
import ajax from '@/api/api-manager';
import { ref, onMounted } from 'vue';
import moment from 'moment';
import Pagination from '@/shared-components/Pagination.vue';

const list = ref([]);
const current_page = ref(1);
const board_list = ref(null);
const content_valid = ref(false);
const page = ref(null);
const totalPage = ref(null);

onMounted(() => {
	pagination();
});

const pagination = () => {
	ajax('servicecenter/noti_list', current_page.value).then((res) => {
		board_list.value = res.data.data;
		page.value = res.data.page;
		totalPage.value = res.data.totalpage;
	});
};

const page_active = (event) => {
	current_page.value = event;
	Array.from(document.querySelectorAll('#down_icon')).map((p) => {
		p.style.transform = 'rotate(360deg)';
	});

	Array.from(document.querySelectorAll('#notice_list')).map((p) => {
		p.style.display = '';
	});
	ajax('servicecenter/noti_list', current_page.value).then((res) => {
		pagination();
	});
};

const openAnswer = (index) => {
	if (document.querySelectorAll('#notice_list')[index].style.display === 'none') {
		document.querySelectorAll('#notice_list')[index].style.display = 'block';
		document.querySelectorAll('#down_icon')[index].style.transform = 'rotate(180deg)';
		document.querySelectorAll('#down_icon')[index].style.transition = 'all ease 0.5s';
		// document.querySelectorAll('.bx bxs-chevron-down')[index].style.transform = 'translateY(10px)';
	} else if (document.querySelectorAll('#notice_list')[index].style.display === 'block') {
		document.querySelectorAll('#down_icon')[index].style.transform = 'rotate(360deg)';
		document.querySelectorAll('#notice_list')[index].style.display = 'none';
	}
};
</script>
